// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'dev',
  production: false,
  region: 'NA',
  appUrl: 'https://device-health.dev.zonarsystems.net/',

  apiBase: {
    url: 'https://api-dev.zonarsystems.net/gtcx-device-health-api/v1'
  },

  geocoderApi: {
    url: 'https://zonar-nonprod-dev.apigee.net'
  },

  here: {
    apiKeyV3: 'iRhaHB0jRt7Np-iRhaHB0jRt7Np-2bHVzybLZ0fIVGvAixLJ0Y5VLbRX4'
  },

  coreEntityApiBase: {
    url: 'https://api-dev.zonarsystems.net'
  },

  userPreferencesApiBase: {
    url: 'https://api-dev.zonarsystems.net/alert/v1'
  },

  //  TODO: Setup your application's authentication variables: see
  //  https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: 'development',

  auth: {
    clientID: 'W7Jj3OvJZUThb5VOfkABS89RSHDuzcJr',
    domain: 'zonar-dev.auth0.com',
    audience: 'http://apidev.zonarsystems.net/',
    // These come from the Core APIs as your application is built.
    applicationId: '259304ab-4ef1-4a18-b17b-f632f659491d',
    defaultZonarRole: '9de87cfe-901f-4c1c-9e53-93dc78302e60',
    useRefreshTokens: true
  },

  pendo: {
    url: 'https://cdn.pendo.io/agent/static/'
  },

  devCycle: {
    clientId: 'dvc_client_273c496b_3c38_4cba_95f9_1e073eeb95a3_5f76e92',
    tabletEventFeature: 'tablet-events-restriction',
    tcuEventFeature: 'tcu-events-restriction',
    rfidEventFeature: 'rfid-events-restriction',
    deviceViewFeature: 'device-overview-restriction'
  },

  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },

  //TODO: Setup your application's datadog app (see empty box readme)
  datadog: {
    applicationId: 'ba51facc-0ff9-4652-b17b-00cfd8744d44',
    clientToken: 'pub9dd0ad51e98fbbf5460bfc5ac520bdff',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'dev'
  },

  i18n: {
    supportedLanguages: [
      'en-US',
      'de-DE',
      'en-GB',
      'fr-FR',
      'es-ES',
      'it-IT',
      'en-CA'
    ],
    defaultLanguage: 'en-US'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
